import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl, Link } from "gatsby-plugin-intl";
import { getIntlData } from "../lib/intl";

import Layout from "../components/layout";
// import localize from "../components/localize";
import * as styles from "./index.module.scss";

const IndexPage = ({ data, intl }) => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          {data.allSanityProject.nodes.map((item) => (
            <div className="col-12 col-md-4" key={item._id}>
              <Link
                title={getIntlData(item.name, intl.locale)}
                to={`/projects/${item.slug.current}`}
                className={styles.gridItem}
              >
                <GatsbyImage
                  image={item.mainImage.image.asset.gatsbyImageData}
                  alt={getIntlData(item.mainImage.alt, intl.locale)}
                  layout="fixed"
                  className="w-100 fluid"
                  imgClassName={styles.image}
                ></GatsbyImage>

                <div className="details">
                  {getIntlData(item.name, intl.locale)} /{" "}
                  {getIntlData(item.location, intl.locale)}
                </div>
              </Link>
            </div>
          ))}
          </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query index {
    allSanityProject {
      nodes {
        _id
        name {
          pt
          en
        }
        slug {
          current
        }
        location {
          pt
          en
        }
        mainImage {
          alt {
            pt
            en
          }
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 600, height: 600)
            }
          }
        }
      }
    }
  }
`;

export default injectIntl(IndexPage);
